import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import { HeaderMobile } from './HeaderMobile';
import { HeaderDesktop } from './HeaderDesktop';

const Header = ({ t, currentPage }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setIsDrawerOpen(open);
  };

  return (
    <AppBar position="static">
      <HeaderDesktop
        t={t}
        currentPage={currentPage}
        onClick={toggleDrawer(true)}
      />
      <HeaderMobile
        t={t}
        currentPage={currentPage}
        isDrawerOpen={isDrawerOpen}
        onClick={toggleDrawer(false)}
      />
    </AppBar>
  );
};

export { Header };
