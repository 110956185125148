import React from 'react';
import Link from 'next/link';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Box } from '@mui/material';
import { LOCALES_ENUM } from '../../constants/locales';
import { PAGE_SITEMAP } from '../../constants/pages';

const LocaleButton = ({ text, href, locale, currentLocale }) => (
  <Link href={href} locale={locale} passHref>
    <Button
      color="inherit"
      sx={{ mr: 1 }}
      aria-disabled={undefined}
      disabled={currentLocale === locale}
      title={text}
    >
      {text}
    </Button>
  </Link>
);

const FooterLink = ({ href, text, currentPage }) => {
  return (
    <Link href={href} passHref>
      <Button
        color="inherit"
        sx={{ mr: 1 }}
        disabled={currentPage === href}
        title={text}
      >
        {text}
      </Button>
    </Link>
  );
};

const Footer = ({ t, currentLocale, currentPage }) => (
  <AppBar
    position="static"
    sx={{
      top: 'auto',
      bottom: 0,
      mt: 'auto',
      py: 1,
      background: 'rgb(26, 33, 56)'
    }}
    component="footer"
  >
    <Toolbar
      sx={{
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          mr: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <FooterLink
          href={PAGE_SITEMAP}
          text={t('Sitemap')}
          currentPage={currentPage}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          gridAutoFlow: 'column',
          gridGap: '12px',
          mb: 'auto',
          flexDirection: 'column'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gridAutoFlow: 'column',

            gridGap: '12px'
          }}
        >
          {LOCALES_ENUM.map((locale) => (
            <LocaleButton
              key={`locale-${locale}`}
              currentLocale={currentLocale}
              href={currentPage}
              locale={locale}
              text={locale.toUpperCase()}
            />
          ))}
        </Box>
      </Box>
    </Toolbar>
  </AppBar>
);

export { Footer };
