import React from 'react';
import { Box } from '@mui/material';
import { NextSeo } from 'next-seo';
import { StructuredBreadcrumb } from './Breadcrumb';

const Layout = ({ children, seoData, breadcrumbsData = [], host, locale }) => (
  <Box as="main">
    <StructuredBreadcrumb
      breadcrumbList={breadcrumbsData}
      separator="›"
      host={host}
      locale={locale}
    />
    <NextSeo {...seoData} />
    {children}
  </Box>
);

export { Layout };
