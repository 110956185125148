import React from 'react';
import LazyHydrate from 'react-lazy-hydration';

const defaultTriggerOn = ['click', 'mouseenter', 'keyup', 'touchstart'];

export const LazyHydrateLayout = ({ Header, Content, Footer }) => (
  <>
    <LazyHydrate on={defaultTriggerOn}>{Header}</LazyHydrate>
    <LazyHydrate on={defaultTriggerOn}>{Content}</LazyHydrate>
    <LazyHydrate ssrOnly>{Footer}</LazyHydrate>
  </>
);
