import React from 'react';
import { Box, Typography, Link as StyledLink } from '@mui/material';
import Link from 'next/link';

const Breadcrumb = ({ text, href, index }) => {
  if (!href) {
    return (
      <>
        <Typography component="span" itemProp="name">
          {text}
        </Typography>
        <meta itemProp="position" content={index} />
      </>
    );
  }

  return (
    <>
      <Link href={`${href}`} passHref>
        <Box
          py="16px"
          component={StyledLink}
          itemProp="item"
          sx={{ textDecoration: 'none' }}
          title={text}
        >
          <Typography component="span" itemProp="name">
            {text}
          </Typography>
        </Box>
      </Link>
      <meta itemProp="position" content={index} />
    </>
  );
};

export const StructuredBreadcrumb = ({ breadcrumbList = [], separator }) => {
  if (breadcrumbList.length === 0) {
    return null;
  }

  return (
    <Box
      component="ol"
      itemScope
      itemType="http://schema.org/BreadcrumbList"
      display="flex"
      sx={{
        px: '40px',
        flexWrap: 'wrap',
        py: 3,
        height: '100%',
        m: 'auto',
        gridGap: '10px',
        backgroundColor: 'rgb(26, 33, 56)',
        backgroundImage:
          'linear-gradient(120deg, rgb(39 49 79) 0%, rgb(26, 33, 56) 100%)'
      }}
    >
      {breadcrumbList.map(({ href, text }, i) => (
        <React.Fragment key={`${text}-${i.toString()}`}>
          {separator && i > 0 ? (
            <Box
              aria-hidden="true"
              component="li"
              sx={{ listStyle: 'none', color: 'rgb(238, 238, 239)' }}
            >
              {separator}
            </Box>
          ) : null}
          <Box
            component="li"
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
            sx={{ listStyle: 'none', color: 'rgb(174, 176, 180)' }}
          >
            <Breadcrumb href={href} text={text} index={i + 1} />
          </Box>
        </React.Fragment>
      ))}
    </Box>
  );
};
