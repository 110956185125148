const { LOCALES_ENUM, LANG_RU, LANG_EN } = require('../../constants/locales');
const { removeTrailingSlash } = require('./urlBuilders/removeTrailingSlash');

const locales = {
  [LANG_RU]: 'ru_RU',
  [LANG_EN]: 'en_US'
};

const generateSeoData = ({
  title,
  description,
  locale,
  host,
  path,
  type,
  images = []
}) => {
  const canonical = removeTrailingSlash(
    locale === LANG_RU ? `${host}${path}` : `${host}/${locale}${path}`
  );

  const languageAlternates = [
    { hrefLang: 'x-default', href: removeTrailingSlash(`${host}${path}`) },
    ...LOCALES_ENUM.map((lang) => ({
      hrefLang: lang,
      href: removeTrailingSlash(
        lang === LANG_RU ? `${host}${path}` : `${host}/${lang}${path}`
      )
    }))
  ];

  const image = `${host}/icon-512x512.png`;

  const additionalLinkTags = [
    { rel: 'shortcut icon', href: '/favicon.ico' },
    { rel: 'apple-touch-icon', href: '/apple-icon.png', sizes: '192x192' },
    { rel: 'icon', href: '/favicon.ico', type: 'image/x-icon' },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '96x96',
      href: '/favicon-96x96.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: '/favicon-32x32.png'
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: '/favicon-16x16.png'
    },
    { rel: 'manifest', href: '/manifest.json' }
  ];

  const additionalMetaTags = [
    { name: 'msapplication-TileColor', content: '#222b45' },
    { name: 'theme-color', content: '#222b45' },
    { name: 'application-name', content: 'AI NEXT' },
    { name: 'apple-mobile-web-app-capable', content: 'yes' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'default' },
    { name: 'apple-mobile-web-app-title', content: 'AI NEXT' }
  ];

  return {
    title,
    description,
    canonical,
    openGraph: {
      type,
      locale: locales[locale],
      url: canonical,
      title,
      images: [
        {
          url: image,
          width: 512,
          height: 512,
          alt: 'AI NEXT'
        },
        ...images
      ],
      description,
      siteName: 'AI NEXT'
    },
    twitter: {
      cardType: 'summary_large_image'
    },
    languageAlternates,
    additionalLinkTags,
    additionalMetaTags
  };
};

module.exports = {
  generateSeoData
};
